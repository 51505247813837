$background-color: #000433;
$main-color: #000458;
$secondary-color: #000346;
$enphasis-color: #6b78b8;
$text-color: #ffffff;
$table-color: #ffffff00;
$text-bgcapa: #ffffff;



$main-color: #00047a; 
$secondary-color: #000446;

$breakpoint-phone: 310px;
$breakpoint-phone2: 500px;
$breakpoint-tablet: 900px;
$breakpoint-large:  1200px;
$breakpoint-extra-large:  1536px;



