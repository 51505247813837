@use "variables";

.form-components {
  background-color: variables.$main-color;
  border-radius: 15px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 1.5em !important;
}

.form-contact {
  background-color: variables.$main-color;
  border-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 1.5em !important;
}

.form-capa-name {
  background-color: variables.$main-color;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 1.5em !important;
  padding-top: 0 !important;
}

.enphasis-button{
  background-color: variables.$enphasis-color;
  border: none;
  border-radius: 20px;
  font-weight: 700;
}

.enphasis-button:hover{
  background-color: variables.$text-color;
  color: variables.$enphasis-color;
}

.tooltip-incognito {
  align-items: center;
  background-color: #23263c;
  border-radius: 100%;
  color: white;
  height: 15px;
  justify-content: center;
  width: 15px;
  display: inline-flex;
  cursor: pointer;
  transform: scale(.90);
  font-size: 12px;
  margin-left: 5px;
  position: relative;

}
.tooltip-incognito .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #ffe270;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: .25rem .75rem .25rem .75rem;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-incognito .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #ffe270 transparent transparent transparent;
}

.tooltip-incognito:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.cursor-denagado {
  cursor: not-allowed;
}

.botton-export{
  background-color: #0b0d1c;
  color: white;
  border: 2px solid #1c2035 ;
  width: 90%;
  margin: auto;
  margin-top: 10px;
  padding: .25rem .75rem .25rem .75rem;
  &:focus{
    background-color: #1c2035;
  }
}

.botton-loging-metaMask{
  font-size: .78rem;
  background-color: #0b0d1c;
  color: #485fc7;
  border: 2px solid #1c2035 ;
  width: 100%;
  margin: 10px auto;
  padding: .25rem .75rem .25rem .75rem;
  &:hover{
    background-color: #485fc7;
    color: white;
  }
  &:focus{
    background-color: #213692;
    color: white;
  }

  
}
.metodosDePagoBox {
    display: flex;
    flex-direction: column;
    height: 8em;
    justify-content: space-between;
    align-items: center;
    width: 8em;
    box-shadow: 2px 1px 8px 1px #ffffff2a;
    cursor: pointer;
    padding: .6em;
    transition: 250ms cubic-bezier(0.43, -0.38, 0.14, 1.58);
    fill: #ffffff !important;   
  }

  .metodosDePagoBox:hover {
    transform: scale(1.05);
    box-shadow: 2px 1px 8px 1px #ffffff33;
    fill: #000000 !important;
  }