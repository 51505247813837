@use "variables";

.capas-container {
  margin: 0;
  list-style: none;
  padding: 5px;
  font-size: 1rem;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .selected-capa {
    background-color: variables.$text-bgcapa !important;
    .capa-name{
      color: variables.$main-color !important;
      width: 40%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

}  
.drag-drop{
  overflow-y: auto;
  max-height: 580px;
}


.drag-drop::-webkit-scrollbar {
  width: .8em;
}
.drag-drop::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.981);
  background-color: #000446;
}
.drag-drop::-webkit-scrollbar-thumb {
  background-color: #00b8ff;
  background-color: #9b0840aa;
  border-radius: 5px;
  // outline: 1px solid slategrey;
}

.capaCreate {
  width: 98% !important;
}

.pencil-button-show {
  cursor: pointer;
  width: 30px;
  height: 30px;
  transition: 400ms cubic-bezier(0.43, -0.38, 0.14, 1.58);
}

.pencil-button-hide {
  width: 0px;
  height: 0px;
  transition: 400ms cubic-bezier(0.43, -0.38, 0.14, 1.58);
}

.delete-button-hide{
  width: 0px;
  height: 0px;
  transition: 400ms cubic-bezier(0.43, -0.38, 0.14, 1.58);
}

.delete-button-show{
  cursor: pointer;
  width: 30px;
  height: 30px;
  transition: 400ms cubic-bezier(0.43, -0.38, 0.14, 1.58);
}
